
import { IonItem, IonLabel, IonChip, IonToast } from "@ionic/vue";
import ActionButton from "./ActionButton.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "MessageListItem",

  data() {
    return {
      codeChip: ""
    };
  },
  components: {
    ActionButton,
    IonItem,
    IonLabel,
    IonChip,
    IonToast
  },
  props: ["word"],

  methods: {
    isIos: () => {
      const win = window as any;
      return win && win.Ionic && win.Ionic.mode === "ios";
    },
    copyChip(value: string) {
      navigator.clipboard.writeText(value);
      this.isOpenRef = true;
    }
  },
  setup() {
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => (isOpenRef.value = state);

    return { isOpenRef, setOpen };
  }
});
