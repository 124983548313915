
import {
  IonBadge,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle
} from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MessageListItem",
  components: {
    IonIcon,
    IonItem,
    IonLabel,
    IonNote,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonBadge
  },
  props: ["message"],
  async mounted() {
    this.words = await this.nouns.substantiv;
  },
  methods: {
    isIos: () => {
      const win = window as any;
      return win && win.Ionic && win.Ionic.mode === "ios";
    }
  },
  data() {
    return { chevronForward, nouns: this.message, words: [] };
  }
});
