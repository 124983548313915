
import { modalController, IonHeader } from "@ionic/vue";
import { defineComponent } from "vue";
import HubspotForm from "./HubspotForm.vue";

export default defineComponent({
  name: "Modal",
  props: ["word"],
  data() {
    return {};
  },
  methods: {
    async close() {
      await modalController.dismiss();
    }
  },
  mounted() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
    });
  },

  components: {
    IonHeader,
    HubspotForm
  }
});
