
import {
  IonSearchbar,
  IonSpinner,
  IonList,
  IonItemDivider,
  IonNote
} from "@ionic/vue";
import MessageListItem from "../components/MessageListItem.vue";
import CoClass from "./CoClass.vue";
import Information from "./Information.vue";

import { defineComponent, ref } from "vue";
import { getForfattning, Byggregler } from "../data/messages";
import { getWords, CoClassWord } from "../data/coclassWords";

export default defineComponent({
  watch: {
    words() {
      this.viewItems = true;
    }
  },
  name: "Home",
  data() {
    return {
      messages: [] as Byggregler[],
      words: [] as CoClassWord[],
      allWords: [] as CoClassWord[],
      search: "",
      isLoading: false,
      viewItems: false
    };
  },
  props: ["page"],
 /* setup() {
    const posts = ref(getWords(0,100));

    return {
      posts
    };
  },*/
  methods: {
    async viewText() {
      this.isLoading = true;
      this.viewItems = false;

      const res = await getForfattning(this.search);
      this.viewItems = true;
      this.messages = res;
      this.isLoading = false;
    },
    refresh: (ev: CustomEvent) => {
      setTimeout(() => {
        ev.detail.complete();
      }, 3000);
    }
  },
  components: {
    IonSearchbar,
    MessageListItem,

    IonSpinner,
    IonList,
    CoClass,
    Information,
    IonItemDivider,
    IonNote
  }
});
