import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-13ea4d7e")
const _hoisted_1 = { style: {"padding-top":"0px"} }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_MessageListItemWords = _resolveComponent("MessageListItemWords")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_item_divider, {
      mode: "ios",
      style: {"background-color":"white","padding-top":"0px","padding-start":"0px","padding-end":"0px","padding-bottom":"0px"},
      sticky: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_searchbar, {
          mode: "ios",
          style: {"padding-top":"0px","padding-bottom":"10px"},
          placeholder: "Sök CoClass-ord",
          type: "text",
          modelValue: _ctx.searchInput,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchInput = $event)),
          onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterWords($event.detail.value))),
          onKeyup: _withKeys(_ctx.searchWord, ["enter"])
        }, null, 8, ["modelValue", "onKeyup"])
      ]),
      _: 1
    }),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ion_spinner, {
          key: 0,
          class: "spinner",
          name: "lines",
          color: "medium"
        }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loadedWords, (word) => {
      return (_openBlock(), _createBlock(_component_MessageListItemWords, {
        word: word,
        key: word.title
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(this.word), 1)
        ]),
        _: 2
      }, 1032, ["word"]))
    }), 128)),
    _createVNode(_component_ion_infinite_scroll, {
      onIonInfinite: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadData($event))),
      threshold: "100px",
      id: "infinite-scroll",
      disabled: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_infinite_scroll_content, {
          "loading-spinner": "bubbles",
          "loading-text": "Loading more data..."
        })
      ]),
      _: 1
    })
  ]))
}