
import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItemDivider,
  IonSearchbar,
  IonSpinner
} from "@ionic/vue";
import MessageListItemWords from "../components/MessageListItemWords.vue";
import { defineComponent, ref } from "vue";
import { CoClassWord, getWords, getWordsBySearch } from "../data/coclassWords";
export default defineComponent({
  name: "Home",
  data() {
    return {
      isLoading: false as boolean,
      searchInput: ""
    };
  },
  props: ["page"],
  mounted() {
    this.$nextTick(function() {
      this.viewCoclassWords();
      console.log("mounted!");
      // this.filteredRows
    });
  },
  updated() {
    console.log("updated!");
    //this.filteredRows
  },
  computed: {},
  methods: {
    async searchWord(e: any) {
      await getWordsBySearch(e.target.value).then(response => {
        this.loadedWords = response.data.body as CoClassWord[];
      });
    },

    async filterWords(e: string) {
      this.searchInput = e;
      this.filteredWords = this.allWords.filter((row: CoClassWord) => {
        return row.title
          .toString()
          .toLowerCase()
          .includes(e.toLowerCase());
      });

      if (this.filteredWords.length > 50) {
        this.loadedWords = this.filteredWords.slice(0, 50);
      } else {
        this.loadedWords = this.filteredWords;
      }
    }
  },
  components: {
    MessageListItemWords,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSpinner,
    IonSearchbar,
    IonItemDivider
  },
  setup() {
    const loadedWords = ref<CoClassWord[]>([]);
    const allWords = ref<CoClassWord[]>([]);
    const filteredWords = ref<CoClassWord[]>([]);
    // TODO: databaseLength has to be queried
    const databaseLength = 24924;
    let skip = 0;
    const viewCoclassWords = async () => {
      while (skip < databaseLength) {
        await getWords(skip, 50).then(response => {
          allWords.value = [
            ...new Set(
              allWords.value.concat(response.data.body as CoClassWord[])
            )
          ];
          if (skip === 0) {
            loadedWords.value = response.data.body as CoClassWord[];
          }
        });
        Promise.resolve(loadedWords.value);
        skip += 50;
      }
    };

    const pushData = async () => {
      let allRelevantWords = allWords;
      if (filteredWords.value.length !== 0) {
        allRelevantWords = filteredWords;
      }
      const newWords = allRelevantWords.value.slice(
        loadedWords.value.length - 1,
        loadedWords.value.length + 49
      );
      loadedWords.value = loadedWords.value.concat(newWords);
    };

    const loadData = async (ev: Record<string, any>) => {
      await pushData();
      ev.target.complete();
      if (loadedWords.value.length >= databaseLength) {
        ev.target.disabled = true;
      }
    };
    pushData();
    return {
      loadData,
      viewCoclassWords,
      loadedWords,
      filteredWords,
      allWords
    };
  }
});
