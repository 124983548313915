import axios from 'axios';

const baseCoClassUrl: string = process.env.VUE_APP_BR_AI_ALL_COCLASS_WORDS;

export interface ResponseCoclassWord {
  statusCode: number;
  body: CoClassWord[];
}

export interface CoClassGuid {
  class: string;
  guid: string;
}

export interface CoClassWord {
  title: string;
  coClassGuids: CoClassGuid[];
  regulations: boolean;
}

export async function getWords(skip: number, limit: number): Promise<any> {
  return axios.get(
    baseCoClassUrl + '?skip_num=' + skip + '&limit_num=' + limit
  );
}

export async function getWordsBySearch(search: string): Promise<any> {
  return axios.get(baseCoClassUrl + '?search_term=' + search.toLowerCase());
}
