import axios from 'axios';

let anArray: string;

async function getData(bfsId: string, paragraf: string) {
  await axios
    .get(
      `https://api.boverket.se/forfattningssamling/v1/forfattningar/${bfsId}/innehall/html?paragraf=${paragraf}`,
      {
        headers: {
          Accept: 'application/json',
        },
      }
    )

    .then(async (response) => {
      anArray = (await response.data) as string;
    });
  return await anArray;
}

export const getWholeParagraph = async (bfsId: string, paragraf: string) =>
  await getData(await bfsId, paragraf);
