import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_InformationButtonModal = _resolveComponent("InformationButtonModal")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ion_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOpen(true)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_icon, {
          icon: _ctx.informationCircleOutline,
          slot: "icon-only"
        }, null, 8, ["icon"])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_modal, {
      "swipe-to-close": true,
      "is-open": _ctx.isOpenRef,
      "css-class": "my-custom-class",
      onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setOpen(false)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InformationButtonModal)
      ]),
      _: 1
    }, 8, ["is-open"])
  ]))
}