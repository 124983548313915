
import { IonCard, IonCardTitle, IonCardContent } from "@ionic/vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  data() {
    return {};
  },
  props: ["page"],

  methods: {},
  components: {
    IonCard,
    IonCardTitle,
    IonCardContent
  }
});
