import axios from 'axios';
const url = process.env.VUE_APP_BR_AI_BBR_BY_COCLASS_GUID;
const apiKey = process.env.VUE_APP_BR_AI_API_KEY;

export interface ResponseBbrRules {
  statusCode: number;
  body: BbrRule[];
}

export interface BbrRule {
  data?: string;
  parentCategory?: string;
  nyckelord?: string[];
  guid?: string;
  parentGuid?: string;
  previousGuid?: string;
  bfsId?: string;
}

let rules: BbrRule[];

async function getData(param: string) {
  await axios
    .get(url + param)

    .then(async (response) => {
      const data = (await response.data) as ResponseBbrRules;
      rules = data.body;
    });
  console.log(url, param);
  return await rules;
}

export const getBbr = async (param: string) => await getData(await param);
