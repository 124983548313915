import axios from 'axios';
const url = `https://api.boverket.se/forfattningssamling/v1/forfattningar/sok?text=`;

export interface Paragrafer {
  paragraf: string;
  rubrik: string;
  utdrag: string;
}

export interface Byggregler {
  id: string;
  forfattning: string;
  titel: string;
  typ: string;
  utdrag: string;
  paragrafer: Paragrafer[];
  substantiv?: string[];
}

let anArray: Byggregler[];


async function getData(param: string) {
  await axios
    .get(url + param, {
      headers: {
        Accept: 'application/json',
      },
    })

    .then(async (response) => {
      anArray = (await response.data) as Byggregler[];
    });
  return await anArray;
}

export const getForfattning = async (param: string) =>
  await getData(await param);
