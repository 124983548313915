<template>
  <iframe class="hubspot" :src="source"></iframe>
</template>

<script>
export default {
  data: () => ({
    source: "hs.html"
  })
};
</script>

<style scoped>
.hubspot {
  width: 100%;
  height: 650px;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}
</style>