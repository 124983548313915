
import { IonModal, IonButton } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import Modal from "./Modal.vue";

export default defineComponent({
  components: { IonModal, IonButton, Modal },
  props: ["word"],
  setup() {
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => (isOpenRef.value = state);
    const data = { content: "New Content" };
    return { isOpenRef, setOpen, data };
  }
});
