
import { IonToolbar, IonButtons } from "@ionic/vue";
import AppToolbarButton from "./AppToolbarButton.vue";
import InformationButton from "./InformationButton.vue";
import { defineComponent } from "vue";
import {
  bookOutline,
  pricetagOutline,
  informationCircleOutline
} from "ionicons/icons";
import Logo from "./Logo.vue";

export default defineComponent({
  name: "Home",
  data() {
    return {
      search: "",
      isLoading: false,
      page: "Home"
    };
  },
  methods: {
    onClickButton(page: string) {
      this.$emit("click", page);
    },

    refresh: (ev: CustomEvent) => {
      setTimeout(() => {
        ev.detail.complete();
      }, 3000);
    }
  },
  components: {
    Logo,
    AppToolbarButton,
    IonToolbar,
    IonButtons,
    InformationButton
  },
  setup() {
    return { bookOutline, pricetagOutline, informationCircleOutline };
  }
});
