<template>
  <img :src="mySVG" />
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      mySVG: require("../assets/byggreglerai.svg")
    };
  }
};
</script>
