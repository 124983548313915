
import {
  modalController,
  IonList,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonSelect,
  IonSelectOption,
  IonText,
  IonSpinner,
  IonButtons,
  IonItem,
  IonButton,
  IonNote,
  IonCard,
  IonIcon
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";
import { getBbr } from "../data/bbrByCoclassGuid";
import { getWholeParagraph } from "../data/bbrByParagraph";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Modal",
  props: ["word"],
  data() {
    return {
      content: "Content",
      rules: [],
      isLoading: false,
      rulesBfsId: [],
      allRules: [],
      countRules: 0,
      isAllrules: true,
      wholeParagraph: ""
    };
  },
  setup() {
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => (isOpenRef.value = state);
    const data = { content: "New Content" };
    return { isOpenRef, setOpen, data, arrowBackOutline };
  },
  methods: {
    async getParagraph(rule: any) {
      this.isLoading = true;
      this.isAllrules = false;
      const wholeParagraph = await getWholeParagraph(
        rule.bfsId,
        rule.paragrafId
      );
      this.wholeParagraph = wholeParagraph.replace(
        rule.utdrag,
        `<b style="background-color:#ffe3da">${rule.utdrag}</b>`
      );
      this.isLoading = false;
    },
    backToRules() {
      this.isAllrules = true;
      this.wholeParagraph = "";
    },
    getCountRules(rules: Record<string, any>) {
      this.countRules = rules.length;
    },
    onFilter(e: Record<string, any>) {
      const filteredValues = e.detail.value;
      if (filteredValues.length == 0) {
        this.rules = this.allRules;
      } else {
        this.rules = this.allRules.filter(rule =>
          filteredValues.includes(rule.bfsId)
        );
      }
      this.getCountRules(this.rules);
    },
    async fetchRules() {
      this.isLoading = true;
      console.log(this.word);
      const rulesToSort = await getBbr(this.word.coClassGuids[0].guid);
      this.rules = rulesToSort.sort((a, b) => b.bfsId.localeCompare(a.bfsId));
      this.allRules = this.rules;
      this.rulesBfsId = [...new Set(this.rules.map(obj => obj.bfsId))];
      this.isLoading = false;
      this.getCountRules(this.rules);
    },
    async close() {
      await modalController.dismiss();
    }
  },
  mounted() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      this.fetchRules();
    });
  },

  components: {
    IonList,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonSelect,
    IonSelectOption,
    IonText,
    IonSpinner,
    IonButtons,
    IonItem,
    IonButton,
    IonNote,
    IonCard,
    IonIcon
  }
});
