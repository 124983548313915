import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-f9c3662e")
const _hoisted_1 = { class: "logo" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_InformationButton = _resolveComponent("InformationButton")!
  const _component_AppToolbarButton = _resolveComponent("AppToolbarButton")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!

  return (_openBlock(), _createBlock(_component_ion_toolbar, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_buttons, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_Logo)
          ]),
          _createVNode(_component_InformationButton),
          _createVNode(_component_AppToolbarButton, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickButton('Byggregler'))),
            icon: _ctx.bookOutline
          }, null, 8, ["icon"]),
          _createVNode(_component_AppToolbarButton, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickButton('CoClass'))),
            icon: _ctx.pricetagOutline
          }, null, 8, ["icon"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}