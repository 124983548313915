
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol
  // IonHeader,
} from "@ionic/vue";
import SearchPage from "../views/SearchPage.vue";

import { defineComponent } from "vue";
import { bookOutline, homeOutline } from "ionicons/icons";

export default defineComponent({
  name: "Home",
  props: ["page"],
  data() {
    return {
      search: "",
      isLoading: false
    };
  },
  methods: {
    refresh: (ev: CustomEvent) => {
      setTimeout(() => {
        ev.detail.complete();
      }, 3000);
    }
  },
  components: {
    IonContent,
    // IonHeader,
    SearchPage,
    IonGrid,
    IonRow,
    IonCol
  },
  setup() {
    return { bookOutline, homeOutline };
  }
});
