
import {
  // IonHeader,
  IonPage
} from "@ionic/vue";
import AppToolbar from "../components/AppToolbar.vue";
import AppContent from "../components/AppContent.vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  data() {
    return {
      page: "CoClass" as string
    };
  },
  methods: {
    onClickChild(value: string) {
      if (typeof value == "string") {
        this.page = value;
      }
    },
    viewPage(page: string) {
      this.page = page;
    },
    refresh: (ev: CustomEvent) => {
      setTimeout(() => {
        ev.detail.complete();
      }, 3000);
    }
  },
  components: {
    IonPage,
    AppToolbar,
    AppContent
  }
});
