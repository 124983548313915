
import { IonIcon, IonButton } from "@ionic/vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  data() {
    return {
      search: "",
      isLoading: false
    };
  },
  props: ["icon"],
  methods: {
    refresh: (ev: CustomEvent) => {
      setTimeout(() => {
        ev.detail.complete();
      }, 3000);
    }
  },
  components: {
    IonIcon,
    IonButton
  }
});
