
import { IonModal, IonButton, IonIcon } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import InformationButtonModal from "./InformationButtonModal.vue";
import { informationCircleOutline } from "ionicons/icons";

export default defineComponent({
  components: { IonModal, IonButton, InformationButtonModal, IonIcon },
  props: ["word"],
  setup() {
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => (isOpenRef.value = state);
    const data = { content: "New Content" };
    return { isOpenRef, setOpen, data, informationCircleOutline };
  }
});
